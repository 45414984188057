// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-doodles-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\doodles.js" /* webpackChunkName: "component---src-pages-projects-doodles-js" */),
  "component---src-pages-projects-eden-food-for-change-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\eden-food-for-change.js" /* webpackChunkName: "component---src-pages-projects-eden-food-for-change-js" */),
  "component---src-pages-projects-lawpro-brochure-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\lawpro-brochure.js" /* webpackChunkName: "component---src-pages-projects-lawpro-brochure-js" */),
  "component---src-pages-projects-lawpro-magazine-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\lawpro-magazine.js" /* webpackChunkName: "component---src-pages-projects-lawpro-magazine-js" */),
  "component---src-pages-projects-logo-designs-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\logo-designs.js" /* webpackChunkName: "component---src-pages-projects-logo-designs-js" */),
  "component---src-pages-projects-ostberg-americas-inc-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\ostberg-americas-inc.js" /* webpackChunkName: "component---src-pages-projects-ostberg-americas-inc-js" */),
  "component---src-pages-projects-pablo-dassen-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\pablo-dassen.js" /* webpackChunkName: "component---src-pages-projects-pablo-dassen-js" */),
  "component---src-pages-projects-the-fueling-station-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\the-fueling-station.js" /* webpackChunkName: "component---src-pages-projects-the-fueling-station-js" */),
  "component---src-pages-projects-the-humble-badger-podcast-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\the-humble-badger-podcast.js" /* webpackChunkName: "component---src-pages-projects-the-humble-badger-podcast-js" */),
  "component---src-pages-projects-titleplus-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\titleplus.js" /* webpackChunkName: "component---src-pages-projects-titleplus-js" */),
  "component---src-pages-projects-wander-with-wonder-js": () => import("D:\\Projects\\WebWork\\CarmenWu\\v2019\\src\\pages\\projects\\wander-with-wonder.js" /* webpackChunkName: "component---src-pages-projects-wander-with-wonder-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Projects\\WebWork\\CarmenWu\\v2019\\.cache\\data.json")

